@import "core/index.scss";
.section.home-featuredcategories {
	padding: 0 0 3rem;
	
	.featuredcategories-list {
		display: flex;
		flex-wrap: wrap;
		padding-top: 1rem;
		width: calc(100% + 1rem);
		margin: 0 -.5rem;
	}

		.list-category {
			width: 25%;
			text-align: center;
			padding: 1rem .5rem;

			@include media {
				display: inline-block;
			}
		}

			.category-imagewrap {
				border: .1rem solid $color-gray-light;
				border-radius: $radius-general;
				padding-bottom: 100%;
				position: relative;
			}

			.category-image {
				position: absolute;
				top: 1rem;
				left: 1rem;
				width: calc(100% - 2rem);
				height: calc(100% - 2rem);
			}

			.category-title {
				margin-top: .6rem;
				display: block;
				font-size: 1rem;
				line-height: 1.2em;
				color: $color-text-blue;
			}
}